import styled from 'styled-components';

export const Column = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column !important;
  gap: 20px;
`;

export const Button = styled.button`
  background: ${({ theme }) => theme.colors.degrade};
  border-radius: 1px;
  border: 0;
  padding: 10px 30px;
  display: flex;
  align-items: center;
  min-width: 120px;
  justify-content: center;
  color: #fff;
  font-weight: 400;
  font-size: 1.4rem;
  transition: box-shadow 0.2s;
  text-decoration: none;
  cursor: pointer;
  margin-top: 20px;

  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  select {
    width: 100%;
    height: 50px;
    color: white;
    padding: 10px 10px;
    border-radius: 50px;
    background: linear-gradient(178.18deg, #ff0000 -13.56%, #760000 158.3%);
    background-color: #444;
    margin: 0px 0px;
    margin-top: 0px;
    font-weight: 600;
    &:first-child {
      margin-top: 0px;
    }
  }
  @media only screen and (max-height: 768px) {
    margin-top: 100px;
  }
`;

export const PasswordWrapper = styled.div`
  margin-top: 0px;
  padding: 0px 5px;
  label {
    padding-left: 10px;
    margin-top: 5px;
  }
`;
export const InputWrapper = styled.div`
  padding: 0px 5px;
  margin-bottom: 0px;
  label {
    padding-left: 10px;
  }
`;
