import styled from 'styled-components';

export const Button = styled.button`
  background: #da291c;
  border-radius: 8px;
  border: 0;
  padding: 16px;
  display: flex;
  align-items: center;
  min-width: 120px;
  justify-content: center;
  color: #fff;
  font-weight: 400;
  font-size: 1.4rem;
  transition: box-shadow 0.2s;
  text-decoration: none;
  cursor: pointer;
  margin-top: 20px;
  width: 100%;

  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`;

export const Forgot = styled.div`
  width: 100%;
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center;
`;
export const Row = styled.div`
  display: flex;
  max-width: 450px;
  justify-content: center;
  flex-direction: row !important;
  gap: 20px;
`;
