export const columnsExtract = [
  {
    name: 'Data',
    selector: (row) => (row.dataFormatada ? row.dataFormatada : 'N/A'),
  },
  {
    name: 'Origem',
    selector: (row) => (row.origem ? row.origem : 'N/A'),
  },
  {
    name: 'Descrição',
    selector: (row) => (row.descricao ? row.descricao : 'N/A'),
  },
  {
    name: 'ID',
    selector: (row) => (row.t_id ? row.t_id : 'N/A'),
  },
  {
    name: 'IDPDV',
    selector: (row) => (row.idpdv ? row.idpdv : 'N/A'),
  },
  {
    name: 'Pontos',
    selector: (row) => (row.pontos ? row.pontos : 'N/A'),
  },
  {
    name: 'Pontos Bônus',
    selector: (row) => (row.bonus ? row.bonus : 'N/A'),
  },
  {
    name: 'Status',
    selector: (row) => (row.status ? row.status : 'N/A'),
  },
];

export const columns = [
  {
    name: 'Data do Aceite',
    sortable: true,
    grow: 1,
    minWidth: '150px',
    selector: (row) => (row.data_aceite ? row.data_aceite : 'N/A'),
  },
  {
    name: 'Hora do Aceite',
    sortable: true,
    grow: 1,
    minWidth: '150px',
    selector: (row) => (row.hora_aceite ? row.hora_aceite : 'N/A'),
  },
  {
    name: 'Data do Último Login',
    sortable: true,
    grow: 1,
    minWidth: '180px',
    selector: (row) => (row.data_ultimo_login ? row.data_ultimo_login : 'N/A'),
  },
  {
    name: 'Hora do Último Login',
    sortable: true,
    grow: 1,
    minWidth: '180px',
    selector: (row) => (row.hora_ultimo_login ? row.hora_ultimo_login : 'N/A'),
  },
  {
    name: 'Id Usuário',
    sortable: true,
    grow: 1,
    minWidth: '120px',
    selector: (row) => (row.usu_id ? row.usu_id : 'N/A'),
  },
  {
    name: 'Usuário bloqueado',
    sortable: true,
    grow: 1,
    minWidth: '120px',
    selector: (row) => {
      if (row.new_status_id === 3) {
        return 'Sim';
      } else {
        return 'Não';
      }
    },
  },
  {
    name: 'Data da atualização de bloqueio',
    sortable: true,
    grow: 1,
    minWidth: '120px',
    selector: (row) => (row.dataAtualizacao ? row.dataAtualizacao : ''),
  },
  {
    name: 'Observacao',
    sortable: true,
    grow: 1,
    minWidth: '120px',
    selector: (row) => (row.observacao ? row.observacao : ''),
  },
  {
    name: 'Nome',
    sortable: true,
    grow: 1,
    minWidth: '330px',
    selector: (row) => (row.nome ? row.nome : 'N/A'),
  },
  {
    name: 'CPF',
    sortable: true,
    grow: 1,
    minWidth: '160px',
    selector: (row) => (row.cpf ? row.cpf : 'N/A'),
  },
  {
    name: 'Cargo',
    sortable: true,
    grow: 1,
    minWidth: '200px',
    selector: (row) => (row.cargo ? row.cargo : 'N/A'),
  },
  {
    name: 'Email',
    sortable: true,
    grow: 0,
    minWidth: '300px',
    selector: (row) => (row.email ? row.email : 'N/A'),
  },
  {
    name: 'Data de nascimento',
    sortable: true,
    grow: 1,
    minWidth: '180px',
    selector: (row) => (row.data_nascimento ? row.data_nascimento : 'N/A'),
  },
  {
    name: 'Celular',
    sortable: true,
    grow: 1,
    minWidth: '180px',
    selector: (row) => (row.celular ? row.celular : 'N/A'),
  },
  {
    name: 'Endereço',
    sortable: true,
    grow: 1,
    minWidth: '300px',
    selector: (row) => (row.endereco ? row.endereco : 'N/A'),
  },
  {
    name: 'Número',
    sortable: true,
    grow: 1,
    selector: (row) => (row.numero ? row.numero : 'N/A'),
  },
  {
    name: 'Complemento',
    sortable: true,
    grow: 1,
    minWidth: '200px',
    selector: (row) => (row.complemento ? row.complemento : 'N/A'),
  },
  {
    name: 'CEP',
    sortable: true,
    grow: 1,
    selector: (row) => (row.cep ? row.cep : 'N/A'),
  },
  {
    name: 'Bairro',
    sortable: true,
    grow: 1,
    minWidth: '200px',
    selector: (row) => (row.bairro ? row.bairro : 'N/A'),
  },
  {
    name: 'Cidade',
    sortable: true,
    grow: 1,
    minWidth: '150px',
    selector: (row) => (row.cidade ? row.cidade : 'N/A'),
  },
  {
    name: 'Estado',
    sortable: true,
    grow: 1,
    selector: (row) => (row.estado ? row.estado : 'N/A'),
  },
  {
    name: 'Regional',
    sortable: true,
    grow: 1,
    selector: (row) => (row.regional ? row.regional : 'N/A'),
  },
  {
    name: 'PDV',
    sortable: true,
    grow: 1,
    minWidth: '150px',
    selector: (row) => (row.pdv ? row.pdv : 'N/A'),
  },
  {
    name: 'Rede',
    sortable: true,
    grow: 1,
    minWidth: '150px',
    selector: (row) => (row.rede ? row.rede : 'N/A'),
  },
  {
    name: 'Pedidos',
    sortable: true,
    grow: 1,
    selector: (row) => (row.pedidos ? row.pedidos : 'N/A'),
  },
  {
    name: 'Vendas',
    sortable: true,
    grow: 1,
    selector: (row) => (row.vendas ? row.vendas : 'N/A'),
  },
  {
    name: 'Pontos Bônus',
    sortable: true,
    minWidth: '150px',
    grow: 1,
    selector: (row) => (row.pontos_bonus ? row.pontos_bonus : 'N/A'),
  },
  {
    name: 'Pontos Aprovados',
    sortable: true,
    minWidth: '180px',
    grow: 1,
    selector: (row) => (row.pontos_aprovados ? row.pontos_aprovados : 'N/A'),
  },
  {
    name: 'Pontos Pendentes',
    sortable: true,
    grow: 1,
    minWidth: '180px',
    selector: (row) => (row.pontos_pendentes ? row.pontos_pendentes : 'N/A'),
  },
  {
    name: 'Pontos Resgatados',
    sortable: true,
    grow: 1,
    minWidth: '180px',
    selector: (row) => (row.pontos_resgatados ? row.pontos_resgatados : 'N/A'),
  },
  {
    name: 'Pontos Reprovados',
    sortable: true,
    grow: 1,
    minWidth: '180px',
    selector: (row) => (row.pontos_reprovados ? row.pontos_reprovados : 'N/A'),
  },
  {
    name: 'Pontos Estornados',
    sortable: true,
    grow: 1,
    minWidth: '180px',
    selector: (row) => (row.pontos_estornados ? row.pontos_estornados : 'N/A'),
  },
  {
    name: 'Pontos Aniversário',
    sortable: true,
    grow: 1,
    minWidth: '180px',
    selector: (row) =>
      row.pontos_aniversario ? row.pontos_aniversario : 'N/A',
  },
  {
    name: 'Saldo',
    sortable: true,
    grow: 1,
    selector: (row) => (row.saldo ? row.saldo : 'N/A'),
  },
  {
    name: 'Pontos Bloqueados Premmiar',
    sortable: true,
    grow: 1,
    minWidth: '200px',
    selector: (row) =>
      row.pontos_bloqueados_ltm ? row.pontos_bloqueados_ltm : 'N/A',
  },
];
