const na = 'N/A';

const columns = {
  4: [
    {
      name: 'Código Venda',
      selector: (row) => row.codigo_venda,
      sortable: true,
      grow: 1,
      minWidth: '150px',
      cell: (row) => <div data-tag="allowRowEvents">{row.codigo_venda}</div>,
    },
    {
      name: 'CPF Promotor',
      selector: (row) => row.cpf_promotor,
      sortable: true,
      grow: 1,
      minWidth: '130px',
      cell: (row) => <div data-tag="allowRowEvents">{row.cpf_promotor}</div>,
    },
    {
      name: 'Nome',
      selector: (row) => row.usu_nome,
      sortable: true,
      grow: 1,
      minWidth: '150px',
      cell: (row) => (
        <div data-tag="allowRowEvents">{row.usu_nome || 'Não cadastrado'}</div>
      ),
    },
    {
      name: 'Cargo',
      selector: (row) => row.cargo_nome,
      sortable: true,
      grow: 1,
      minWidth: '150px',
      cell: (row) => <div data-tag="allowRowEvents">{row.cargo_nome}</div>,
    },
    {
      name: 'Rede',
      selector: (row) => row.rede,
      sortable: true,
      grow: 1,
      minWidth: '250px',
      cell: (row) => <div data-tag="allowRowEvents">{row.rede}</div>,
    },
    {
      name: 'Regional',
      selector: (row) => row.regional,
      sortable: true,
      grow: 1,
      minWidth: '100px',
      cell: (row) => <div data-tag="allowRowEvents">{row.regional}</div>,
    },
    {
      name: 'PDV',
      selector: (row) => row.pdv,
      sortable: true,
      grow: 1,
      minWidth: '100px',
      cell: (row) => <div data-tag="allowRowEvents">{row.pdv}</div>,
    },
    {
      name: 'Plano',
      selector: (row) => row.plano,
      sortable: true,
      grow: 1,
      minWidth: '250px',
      cell: (row) => <div data-tag="allowRowEvents">{row.plano}</div>,
    },
    {
      name: 'Plano Tipo',
      selector: (row) => row.plano_tipo,
      sortable: true,
      grow: 1,
      minWidth: '150px',
      cell: (row) => <div data-tag="allowRowEvents">{row.plano_tipo}</div>,
    },
    {
      name: 'Serviço Tipo',
      selector: (row) => row.servico_tipo,
      sortable: true,
      grow: 1,
      minWidth: '130px',
      cell: (row) => <div data-tag="allowRowEvents">{row.servico_tipo}</div>,
    },
    {
      name: 'Pontos Vendas',
      selector: (row) => row.pontos_vendas,
      sortable: true,
      grow: 1,
      cell: (row) => <div data-tag="allowRowEvents">{row.pontos_vendas}</div>,
    },
    {
      name: 'Pontos Bônus',
      selector: (row) => row.pontos_bonus,
      sortable: true,
      grow: 1,
      cell: (row) => <div data-tag="allowRowEvents">{row.pontos_bonus}</div>,
    },
    {
      name: 'STATUS SIV',
      selector: (row) => (row.siv_status ? row.siv_status : na),
      sortable: true,
      grow: 1,
      minWidth: '150px',
      cell: (row) =>
        row.siv_status ? (
          <div data-tag="allowRowEvents">{row.siv_status}</div>
        ) : (
          na
        ),
    },
    {
      name: 'VALIDAÇÃO CLARO',
      selector: (row) => (row.ponto_status ? row.ponto_status : na),
      sortable: true,
      grow: 1,
      minWidth: '130px',
      cell: (row) =>
        row.ponto_status ? (
          <div data-tag="allowRowEvents">{row.ponto_status}</div>
        ) : (
          na
        ),
    },
    {
      name: 'TRANSAÇÃO STATUS',
      selector: (row) => (row.transacao_status ? row.transacao_status : na),
      sortable: true,
      grow: 1,
      minWidth: '150px',
      cell: (row) =>
        row.transacao_status ? (
          <div data-tag="allowRowEvents">{row.transacao_status}</div>
        ) : (
          na
        ),
    },
    {
      name: 'Data Venda',
      selector: (row) => row.data_venda,
      sortable: true,
      grow: 1,
      minWidth: '200px',
      cell: (row) => <div data-tag="allowRowEvents">{row.data_venda}</div>,
    },
    {
      name: 'Portabilidade',
      selector: (row) => row.portabilidade,
      sortable: true,
      grow: 1,
      minWidth: '150px',
      cell: (row) => <div data-tag="allowRowEvents">{row.portabilidade}</div>,
    },
    {
      name: 'Msidn',
      selector: (row) => row.msidn,
      sortable: true,
      grow: 1,
      minWidth: '150px',
      cell: (row) => <div data-tag="allowRowEvents">{row.msidn}</div>,
    },
  ],
  2: [
    {
      name: 'Codigo Venda',
      selector: (row) => row.codigo_venda,
      sortable: true,
      grow: 1,
      minWidth: '150px',
      cell: (row) => <div data-tag="allowRowEvents">{row.codigo_venda}</div>,
    },
    {
      name: 'CPF Promotor',
      selector: (row) => row.cpf_promotor,
      sortable: true,
      grow: 1,
      minWidth: '130px',
      cell: (row) => <div data-tag="allowRowEvents">{row.cpf_promotor}</div>,
    },
    {
      name: 'Nome',
      selector: (row) => row.usu_nome,
      sortable: true,
      grow: 1,
      minWidth: '150px',
      cell: (row) => (
        <div data-tag="allowRowEvents">{row.usu_nome || 'Não cadastrado'}</div>
      ),
    },
    {
      name: 'Cargo',
      selector: (row) => row.cargo_nome,
      sortable: true,
      grow: 1,
      minWidth: '150px',
      cell: (row) => <div data-tag="allowRowEvents">{row.cargo_nome}</div>,
    },
    {
      name: 'Rede',
      selector: (row) => row.rede,
      sortable: true,
      grow: 1,
      minWidth: '250px',
      cell: (row) => <div data-tag="allowRowEvents">{row.rede}</div>,
    },
    {
      name: 'Regional',
      selector: (row) => row.regional,
      sortable: true,
      grow: 1,
      minWidth: '100px',
      cell: (row) => <div data-tag="allowRowEvents">{row.regional}</div>,
    },
    {
      name: 'PDV',
      selector: (row) => row.pdv,
      sortable: true,
      grow: 1,
      minWidth: '100px',
      cell: (row) => <div data-tag="allowRowEvents">{row.pdv}</div>,
    },
    {
      name: 'Plano',
      selector: (row) => row.plano,
      sortable: true,
      grow: 1,
      minWidth: '250px',
      cell: (row) => <div data-tag="allowRowEvents">{row.plano}</div>,
    },
    {
      name: 'Plano Tipo',
      selector: (row) => row.plano_tipo,
      sortable: true,
      grow: 1,
      minWidth: '150px',
      cell: (row) => <div data-tag="allowRowEvents">{row.plano_tipo}</div>,
    },
    {
      name: 'Serviço Tipo',
      selector: (row) => row.servico_tipo,
      sortable: true,
      grow: 1,
      minWidth: '130px',
      cell: (row) => <div data-tag="allowRowEvents">{row.servico_tipo}</div>,
    },
    {
      name: 'Fidelidade',
      selector: (row) => row.fidelidade,
      sortable: true,
      grow: 1,
      minWidth: '200px',
      cell: (row) => <div data-tag="allowRowEvents">{row.fidelidade}</div>,
    },
    {
      name: 'Pontos Vendas',
      selector: (row) => row.pontos_vendas,
      sortable: true,
      grow: 1,
      cell: (row) => <div data-tag="allowRowEvents">{row.pontos_vendas}</div>,
    },
    {
      name: 'Pontos Bônus',
      selector: (row) => row.pontos_bonus,
      sortable: true,
      grow: 1,
      cell: (row) => <div data-tag="allowRowEvents">{row.pontos_bonus}</div>,
    },
    {
      name: 'STATUS SIV',
      selector: (row) => row.siv_status,
      sortable: true,
      grow: 1,
      minWidth: '150px',
      cell: (row) => <div data-tag="allowRowEvents">{row.siv_status}</div>,
    },
    {
      name: 'VALIDAÇÃO CLARO',
      selector: (row) => row.ponto_status,
      sortable: true,
      grow: 1,
      minWidth: '130px',
      cell: (row) => <div data-tag="allowRowEvents">{row.ponto_status}</div>,
    },
    {
      name: 'TRANSAÇÃO STATUS',
      selector: (row) => row.transacao_status,
      sortable: true,
      grow: 1,
      minWidth: '150px',
      cell: (row) => (
        <div data-tag="allowRowEvents">{row.transacao_status}</div>
      ),
    },
    {
      name: 'Data Venda',
      selector: (row) => row.data_venda,
      sortable: true,
      grow: 1,
      minWidth: '200px',
      cell: (row) => <div data-tag="allowRowEvents">{row.data_venda}</div>,
    },
    {
      name: 'Portabilidade',
      selector: (row) => row.portabilidade,
      sortable: true,
      grow: 1,
      minWidth: '150px',
      cell: (row) => <div data-tag="allowRowEvents">{row.portabilidade}</div>,
    },
    {
      name: 'Msidn',
      selector: (row) => row.msidn,
      sortable: true,
      grow: 1,
      minWidth: '150px',
      cell: (row) => <div data-tag="allowRowEvents">{row.msidn}</div>,
    },
    {
      name: 'Motivo de reprovação',
      selector: (row) => row.Motivo_reprovacao,
      sortable: true,
      grow: 1,
      minWidth: '150px',
      cell: (row) => (
        <div data-tag="allowRowEvents">{row.Motivo_reprovacao}</div>
      ),
    },
  ],
  6: [
    {
      name: 'Codigo Venda',
      selector: (row) => row.codigo_venda,
      sortable: true,
      grow: 1,
      minWidth: '150px',
      cell: (row) => <div data-tag="allowRowEvents">{row.codigo_venda}</div>,
    },
    {
      name: 'Codigo Loja',
      selector: (row) => row.cod_loja,
      sortable: true,
      grow: 1,
      minWidth: '150px',
      cell: (row) => <div data-tag="allowRowEvents">{row.cod_loja}</div>,
    },
    {
      name: 'CPF',
      selector: (row) => row.cpf,
      sortable: true,
      grow: 1,
      minWidth: '130px',
      cell: (row) => <div data-tag="allowRowEvents">{row.cpf}</div>,
    },
    {
      name: 'Nome',
      selector: (row) => row.nome,
      sortable: true,
      grow: 1,
      minWidth: '150px',
      cell: (row) => (
        <div data-tag="allowRowEvents">{row.nome || 'Não cadastrado'}</div>
      ),
    },
    {
      name: 'Cargo',
      selector: (row) => row.cargo,
      sortable: true,
      grow: 1,
      minWidth: '150px',
      cell: (row) => <div data-tag="allowRowEvents">{row.cargo}</div>,
    },
    {
      name: 'Regional',
      selector: (row) => row.regional,
      sortable: true,
      grow: 1,
      minWidth: '150px',
      cell: (row) => <div data-tag="allowRowEvents">{row.regional}</div>,
    },
    {
      name: 'SAP',
      selector: (row) => row.sap,
      sortable: true,
      grow: 1,
      minWidth: '100px',
      cell: (row) => <div data-tag="allowRowEvents">{row.sap}</div>,
    },
    {
      name: 'Tier',
      selector: (row) => row.tier,
      sortable: true,
      grow: 1,
      minWidth: '100px',
      cell: (row) => <div data-tag="allowRowEvents">{row.tier}</div>,
    },
    {
      name: 'Modelo',
      selector: (row) => row.modelo,
      sortable: true,
      grow: 1,
      minWidth: '330px',
      cell: (row) => <div data-tag="allowRowEvents">{row.modelo}</div>,
    },
    {
      name: 'Tipo',
      selector: (row) => row.tipo,
      sortable: true,
      grow: 1,
      minWidth: '150px',
      cell: (row) => <div data-tag="allowRowEvents">{row.tipo}</div>,
    },
    {
      name: 'Login Rede',
      selector: (row) => row.login_rede,
      sortable: true,
      grow: 1,
      minWidth: '200px',
      cell: (row) => <div data-tag="allowRowEvents">{row.login_rede}</div>,
    },
    {
      name: 'Pontos Vendas',
      selector: (row) => row.pontos_vendas,
      sortable: true,
      grow: 1,
      minWidth: '140px',
      cell: (row) => <div data-tag="allowRowEvents">{row.pontos_vendas}</div>,
    },
    {
      name: 'Pontos Bônus',
      selector: (row) => row.pontos_bonus,
      sortable: true,
      grow: 1,
      minWidth: '140px',
      cell: (row) => <div data-tag="allowRowEvents">{row.pontos_bonus}</div>,
    },
    {
      name: 'Venda Status',
      selector: (row) => row.venda_status,
      sortable: true,
      grow: 1,
      minWidth: '150px',
      cell: (row) => <div data-tag="allowRowEvents">{row.venda_status}</div>,
    },
    {
      name: 'Data Venda',
      selector: (row) => row.data_venda,
      sortable: true,
      grow: 1,
      minWidth: '200px',
      cell: (row) => <div data-tag="allowRowEvents">{row.data_venda}</div>,
    },
  ],
  8: [
    {
      name: 'ID Venda',
      selector: (row) => (row.id ? row.id : na),
      sortable: true,
      grow: 1,
    },
    {
      name: 'CPF',
      selector: (row) => (row.cpf ? row.cpf : na),
      sortable: true,
      grow: 1,
    },
    {
      name: 'NOME',
      selector: (row) => row.nome ?? na,
      sortable: true,
      grow: 1,
    },
    {
      name: 'CARGO',
      selector: (row) => row.cargo ?? na,
      sortable: true,
      grow: 1,
    },
    {
      name: 'CANAL',
      selector: (row) => (row.canal ? row.canal : na),
      sortable: true,
      grow: 1,
    },
    {
      name: 'REGIONAL',
      selector: (row) => (row.regional ? row.regional : na),
      sortable: true,
      grow: 1,
    },
    {
      name: 'G20',
      selector: (row) => (row.g20 ? row.g20 : na),
      sortable: true,
      grow: 1,
    },
    {
      name: 'Equipe de Venda',
      selector: (row) => (row.equipe_venda ? row.equipe_venda : 0),
      sortable: true,
      grow: 1,
    },
    {
      name: 'Data Venda',
      selector: (row) => (row.data_venda ? row.data_venda : na),
      sortable: true,
      grow: 1,
    },
    {
      name: 'Plano TV',
      selector: (row) => (row.plano_tv ? row.plano_tv : na),
      sortable: true,
      grow: 1,
    },
    {
      name: 'Plano Internet',
      selector: (row) => (row.plano_internet ? row.plano_internet : na),
      sortable: true,
      grow: 1,
    },
    {
      name: 'Plano Fone',
      selector: (row) => (row.plano_fone ? row.plano_fone : na),
      sortable: true,
      grow: 1,
    },
    {
      name: 'Plano Celular',
      selector: (row) => (row.plano_celular ? row.plano_celular : na),
      sortable: true,
      grow: 1,
    },
    {
      name: 'Pontos',
      selector: (row) => (row.pontos_vendas ? row.pontos_vendas : na),
      sortable: true,
      grow: 1,
    },
    {
      name: 'Ponto Bônus',
      selector: (row) => (row.pontos_bonus ? row.pontos_bonus : na),
      sortable: true,
      grow: 1,
    },
    {
      name: 'Status',
      selector: (row) => (row.status ? row.status : na),
      sortable: true,
      grow: 1,
    },
    {
      name: 'Motivo',
      selector: (row) => (row.motivo ? row.motivo : na),
      sortable: true,
      grow: 1,
    },
    {
      name: 'Data Atualização',
      selector: (row) => (row.data_atualizacao ? row.data_atualizacao : na),
      sortable: true,
      grow: 1,
    },
  ],
  5: [
    {
      name: 'Codigo Venda',
      selector: (row) => row.codigo_venda,
      sortable: true,
      grow: 1,
      minWidth: '150px',
      cell: (row) => <div data-tag="allowRowEvents">{row.codigo_venda}</div>,
    },
    {
      name: 'Codigo Serviço',
      selector: (row) => row.codigo_servico,
      sortable: true,
      grow: 1,
      minWidth: '150px',
      cell: (row) => <div data-tag="allowRowEvents">{row.codigo_servico}</div>,
    },
    {
      name: 'Data Venda',
      selector: (row) => row.data_venda,
      sortable: true,
      grow: 1,
      minWidth: '150px',
      cell: (row) => <div data-tag="allowRowEvents">{row.data_venda}</div>,
    },
    {
      name: 'CPF/CNPJ',
      selector: (row) => row.cpf_promotor,
      sortable: true,
      grow: 1,
      minWidth: '150px',
      cell: (row) => <div data-tag="allowRowEvents">{row.cpf_promotor}</div>,
    },
    {
      name: 'Nome Vendedor',
      selector: (row) => row.nome_vendedor,
      sortable: true,
      grow: 1,
      minWidth: '150px',
      cell: (row) => <div data-tag="allowRowEvents">{row.nome_vendedor}</div>,
    },
    {
      name: 'Cargo',
      selector: (row) => row.cargo,
      sortable: true,
      grow: 1,
      minWidth: '150px',
      cell: (row) => <div data-tag="allowRowEvents">{row.cargo}</div>,
    },
    {
      name: 'Regional',
      selector: (row) => row.REGIONAL,
      sortable: true,
      grow: 1,
      minWidth: '150px',
      cell: (row) => <div data-tag="allowRowEvents">{row.REGIONAL}</div>,
    },
    {
      name: 'PDV',
      selector: (row) => row.pdv,
      sortable: true,
      grow: 1,
      minWidth: '150px',
      cell: (row) => <div data-tag="allowRowEvents">{row.pdv}</div>,
    },

    {
      name: 'DDD',
      selector: (row) => row.DDD,
      sortable: true,
      grow: 1,
      minWidth: '150px',
      cell: (row) => <div data-tag="allowRowEvents">{row.DDD}</div>,
    },

    {
      name: 'Cidade',
      selector: (row) => row.CIDADE,
      sortable: true,
      grow: 1,
      minWidth: '150px',
      cell: (row) => <div data-tag="allowRowEvents">{row.CIDADE}</div>,
    },

    {
      name: 'COD CID IBGE',
      selector: (row) => row.COD_CID_IBGE,
      sortable: true,
      grow: 1,
      minWidth: '150px',
      cell: (row) => <div data-tag="allowRowEvents">{row.COD_CID_IBGE}</div>,
    },

    {
      name: 'Plano',
      selector: (row) => row.plano,
      sortable: true,
      grow: 1,
      minWidth: '250px',
      cell: (row) => <div data-tag="allowRowEvents">{row.plano}</div>,
    },
    {
      name: 'Plano Tipo',
      selector: (row) => row.plano_tipo,
      sortable: true,
      grow: 1,
      minWidth: '150px',
      cell: (row) => <div data-tag="allowRowEvents">{row.plano_tipo}</div>,
    },
    {
      name: 'Tipo Serviço',
      selector: (row) => row.tipo_servico,
      sortable: true,
      grow: 1,
      minWidth: '150px',
      cell: (row) => <div data-tag="allowRowEvents">{row.tipo_servico}</div>,
    },
    {
      name: 'ICCID',
      selector: (row) => row.iccid,
      sortable: true,
      grow: 1,
      minWidth: '130px',
      cell: (row) => <div data-tag="allowRowEvents">{row.iccid}</div>,
    },
    {
      name: 'Status',
      selector: (row) => row.status,
      sortable: true,
      grow: 1,
      minWidth: '200px',
      cell: (row) => <div data-tag="allowRowEvents">{row.status}</div>,
    },
    {
      name: 'Quantidade',
      selector: (row) => row.quantidade,
      sortable: true,
      grow: 1,
      minWidth: '100px',
      cell: (row) => <div data-tag="allowRowEvents">{row.quantidade}</div>,
    },
    {
      name: 'Pontos Vendas',
      selector: (row) => row.pontos_vendas,
      sortable: true,
      grow: 1,
      minWidth: '140px',
      cell: (row) => <div data-tag="allowRowEvents">{row.pontos_vendas}</div>,
    },
    {
      name: 'Pontos Bônus',
      selector: (row) => row.pontos_bonus,
      sortable: true,
      grow: 1,
      minWidth: '140px',
      cell: (row) => <div data-tag="allowRowEvents">{row.pontos_bonus}</div>,
    },
    {
      name: 'PERFIL DETALHE',
      selector: (row) => row['Perfil Detalhe'] ?? na,
      sortable: true,
      cell: (row) => (
        <div data-tag="allowRowEvents">{row['Perfil Detalhe']}</div>
      ),
    },
  ],
};

export default columns;
