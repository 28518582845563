import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { parseJwt } from '~/services/auth';
import AdminLayout from '~/styles/Layouts/NexAdmin';

const NexAdminRoute = ({ component: Component, ...rest }) => {
  // Checa se o usuário não tem o token de admin
  if (
    localStorage.getItem('userToken') &&
    !localStorage.getItem('adminUserToken')
  ) {
    return (
      <Route
        {...rest}
        render={(props) => (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location },
            }}
          />
        )}
      />
    );
  }

  // Redireciona para o login de admin se não tiver o token de admin
  if (!localStorage.getItem('adminUserToken')) {
    return (
      <Route
        {...rest}
        render={(props) => (
          <Redirect
            to={{
              pathname: '/admin',
              state: { from: props.location },
            }}
          />
        )}
      />
    );
  }

  // Verifica o tipo de usuário
  if (parseJwt().type !== 'admin') {
    return (
      <Redirect
        to={{
          pathname: '/',
          state: { from: props.location },
        }}
      />
    );
  }

  // Renderiza a rota com o layout de admin e adiciona a meta tag noindex
  return (
    <AdminLayout>
      <Route {...rest} render={(props) => <Component {...props} />} />
    </AdminLayout>
  );
};

export default NexAdminRoute;
