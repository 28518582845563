import { useState, useEffect, useRef } from 'react';
import { parseJwt } from '../services/auth';
import regulationLinks from '../screens/Regulations/regulationLinks';

const useRegulationLink = () => {
  const [regulationLink, setRegulationLink] = useState(null);
  const isMounted = useRef(true);

  useEffect(() => {
    const fetchRegulationLink = () => {
      const jwtData = parseJwt();
      const canalId = jwtData.canal_id || localStorage.getItem('channelId');
      const cargo = jwtData.cargo;
      let link = null;

      switch (canalId) {
        case '2':
        case 2:
          link = [75, 79, 85].includes(cargo)
            ? regulationLinks.varejo.bko
            : regulationLinks.varejo.default;
          break;
        case '4':
        case 4:
          link = regulationLinks.agenteAutorizado.default;
          break;
        case '5':
        case 5:
          link = regulationLinks.recarga.default;
          break;
        case '6':
        case 6:
          link = regulationLinks.aparelhos.default;
          break;
        case '8':
        case 8:
          link = [76, 75, 91].includes(cargo)
            ? regulationLinks.pap.bko
            : regulationLinks.pap.default;
          break;
        case '9':
        case 9:
          link = [93, 94].includes(cargo)
            ? regulationLinks.pc.premium
            : [95, 98, 99].includes(cargo)
            ? regulationLinks.pc.condominio
            : [100, 102, 103].includes(cargo)
            ? regulationLinks.pc.condominioBko
            : [101, 105].includes(cargo)
            ? regulationLinks.pc.premiumBko
            : null;
          break;
        default:
          break;
      }

      if (isMounted.current) {
        setRegulationLink(link);
      }
    };

    fetchRegulationLink();

    return () => {
      isMounted.current = false;
    };
  }, []);

  return regulationLink;
};

export default useRegulationLink;
