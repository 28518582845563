import { useState, useEffect, useRef } from 'react';
import { parseJwt } from '../services/auth';
import tableLinks from '../screens/TablePoints/tableLinks';

const useTableLink = () => {
  const [tableLink, settableLink] = useState(null);
  const isMounted = useRef(true);
  useEffect(() => {
    const fetchtableLink = () => {
      const jwtData = parseJwt();
      const canalId = jwtData.canal_id || localStorage.getItem('channelId');
      const cargo = jwtData.cargo;
      let link = null;

      switch (canalId) {
        case '2':
        case 2:
          link = [75, 79, 85].includes(cargo)
            ? tableLinks.varejo.bko
            : tableLinks.varejo.default;
          break;
        case '4':
        case 4:
          link = tableLinks.agenteAutorizado.default;
          break;
        case '5':
        case 5:
          link = tableLinks.recarga.default;
          break;
        case '6':
        case 6:
          link = tableLinks.aparelhos.default;
          break;
        case '8':
        case 8:
          link = [76, 75, 91].includes(cargo)
            ? tableLinks.pap.bko
            : tableLinks.pap.default;
          break;
        case '9':
        case 9:
          link = [93, 94].includes(cargo)
            ? tableLinks.pc.premium
            : [95, 98, 99].includes(cargo)
            ? tableLinks.pc.condominio
            : [100, 102, 103].includes(cargo)
            ? tableLinks.pc.condominioBko
            : [101, 105].includes(cargo)
            ? tableLinks.pc.premiumBko
            : null;
          break;
        default:
          break;
      }

      if (isMounted.current) {
        settableLink(link);
      }
    };

    fetchtableLink();

    return () => {
      isMounted.current = false;
    };
  }, []);

  return tableLink;
};

export default useTableLink;
