const regulationLinks = {
  varejo: {
    bko:
      'https://s3.amazonaws.com/clarogiga2.1/REGULAMENTOS_PADRONIZADO/VAREJO/Tabela-de-pontos_Varejo_bko.pdf',
    default:
      'https://s3.amazonaws.com/clarogiga2.1/REGULAMENTOS_PADRONIZADO/VAREJO/Tabela-de-pontos_Varejo.pdf',
  },
  agenteAutorizado: {
    default:
      'https://s3.amazonaws.com/clarogiga2.1/REGULAMENTOS_PADRONIZADO/AGENTE_AUTORIZADO/Tabela-de-pontos_Agente-Autorizado.pdf',
  },
  recarga: {
    default:
      'https://s3.amazonaws.com/clarogiga2.1/REGULAMENTOS_PADRONIZADO/DISTRIBUICAO/Tabela-de-Pontos_Distribuicao.pdf',
  },
  aparelhos: {
    default:
      'https://s3.amazonaws.com/clarogiga2.1/REGULAMENTOS_PADRONIZADO/APARELHOS/Tabela-de-pontos_Aparelhos.pdf',
  },
  pap: {
    default:
      'https://s3.amazonaws.com/clarogiga2.1/REGULAMENTOS_PADRONIZADO/PAP/Tabela-de-pontos_PAP-indireto.pdf',
    bko:
      'https://s3.amazonaws.com/clarogiga2.1/REGULAMENTOS_PADRONIZADO/PAP/Tabela-de-pontos_PAP-indireto.pdf',
  },
  pc: {
    premiumBko:
      'https://s3.amazonaws.com/clarogiga2.1/REGULAMENTOS_PADRONIZADO/PAP_PREMIUM/Regulamento_Premium-BKO_2024.pdf',
    condominioBko:
      'https://s3.amazonaws.com/clarogiga2.1/REGULAMENTOS_PADRONIZADO/PAP_CONDOMINIO/Regulamento_Condominio_BKO_2024.pdf',
    premium:
      'https://s3.amazonaws.com/clarogiga2.1/REGULAMENTOS_PADRONIZADO/PAP_PREMIUM/Regulamento_Premium_2024.pdf',
    condominio:
      'https://s3.amazonaws.com/clarogiga2.1/REGULAMENTOS_PADRONIZADO/PAP_CONDOMINIO/Regulamento_Condominio_2024.pdf',
  },
};

export default regulationLinks;
