import Cards from '~/components/NexAdmin/Cards';
import api from '~/services/api.js';
import Download from '~/components/NexAdmin/Table/Components/Download';
import * as S from '../../styles';
import Loading from '~/components/Loading';
import CopyToClipboard from '~/components/NexAdmin/CopyToClipboard';
import { Button } from '~/components/NexAdmin/Buttons';
import { parseJwt } from '~/services/auth';
import { showNotification } from '~/utils/notificationBoxes';
import { useEffect, useState } from 'react';
import { toDateTime } from '~/Helpers/Date';
import { columnsExtract, columns } from './columns';
import { HTTPErrorCallback } from '~/Helpers/Error';
import { restrictAccess, allowAccess } from '~/Helpers/RestrictAccess';
import { Link } from 'react-router-dom';
import { adminIsFromClaro } from '~/utils';

const UserData = ({ setStep, row }) => {
  const [extract, setExtract] = useState([]);
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState('');
  const [accept, setAccept] = useState();
  const [personal, setPersonal] = useState();
  const [points, setPoints] = useState();
  const { canal_id, rede } = parseJwt();
  useEffect(() => {
    userExtract();
    setAccept(buildColumns(columns, 0, 7));
    setPersonal(buildColumns(columns, 8, 23));
    setPoints(buildColumns(columns, 24, 34));
  }, []);

  const userExtract = () => {
    setLoading(true);
    api
      .get(`/list-user/user-extract-admin/${row.usu_id}`)
      .then((response) => {
        setLoading(false);
        setExtract(response.data);
      })
      .catch((error) => {
        setLoading(false);
        return HTTPErrorCallback(error);
      });
  };

  const mirrorAccess = (userCpf) => {
    setLoading(true);
    api
      .get(`/acesso-espelho/${userCpf}`)
      .then((response) => {
        setLoading(false);
        localStorage.setItem('userToken', response.data.token);
        localStorage.setItem('canal', canal_id);
        localStorage.setItem('rede', rede);
        window.open(`http://${window.location.host}/dashboard`);
      })
      .catch((error) => {
        setLoading(false);
        return HTTPErrorCallback(error);
      });
  };

  const enableUserToEditProfile = (userCpf) => {
    setLoading(true);
    api
      .put(`/list-user/enable-profile-changes/${userCpf}`)
      .then(() => {
        setLoading(false);
        return showNotification(
          'success',
          'Sucesso!',
          'Edição de perfil liberada com sucesso.',
        );
      })
      .catch((error) => {
        setLoading(false);
        return HTTPErrorCallback(error);
      });
  };

  const getUserLoginToken = (userCpf) => {
    setLoading(true);
    api
      .get(`/list-user/user-login-token/${userCpf}`)
      .then((response) => {
        setUrl(response.data);
        setLoading(false);
        return;
      })
      .catch((error) => {
        setLoading(false);
        return HTTPErrorCallback(error);
      });
  };

  const getUserResetPasswordToken = (userCpf) => {
    setLoading(true);
    api
      .get(`/list-user/reset-password-token/${userCpf}`)
      .then((response) => {
        setUrl(response.data);
        setLoading(false);
        return;
      })
      .catch((error) => {
        setLoading(false);
        return HTTPErrorCallback(error);
      });
  };

  const inactiveUser = (id) => {
    setLoading(true);
    api
      .patch(`/list-user/inactivate-user/${id}`)
      .then(() => {
        setLoading(false);
        return showNotification(
          'success',
          'Sucesso!',
          'Usuário inativado com sucesso.',
        );
      })
      .catch((error) => {
        setLoading(false);
        return HTTPErrorCallback(error);
      });
  };

  const activeUser = (id) => {
    setLoading(true);
    api
      .patch(`/list-user/activate-user/${id}`)
      .then(() => {
        setLoading(false);
        return showNotification(
          'success',
          'Sucesso!',
          'Usuário ativado com sucesso.',
        );
      })
      .catch((error) => {
        setLoading(false);
        return HTTPErrorCallback(error);
      });
  };

  const buildColumns = (list, initialIndex, finalIndex) => {
    const final = [];
    for (let i = initialIndex; i <= finalIndex; i++) {
      final.push(new Array(list[i].name, list[i].selector(row)));
    }
    return final;
  };

  const switchButtons = () => {
    switch (canal_id) {
      case 2:
        return (
          <Button>
            <Link
              to={{
                pathname: '/admin/sales',
                state: { cpf: row.cpf },
              }}
              style={{ textDecoration: 'none', color: '#323133' }}
            >
              Vendas
            </Link>
          </Button>
        );
      case 5:
      case 8:
        return (
          ![47, 46, 44, 43].includes(parseJwt().usu_nivel) && (
            <Button onClick={() => getUserResetPasswordToken(row.cpf)}>
              Gerar Token de Reset de Senha
            </Button>
          )
        );
      case 6:
        return (
          <Button onClick={() => getUserLoginToken(row.cpf)}>
            Gerar Token
          </Button>
        );
      default:
        return <></>;
    }
  };

  const forcePasswordChange = (userCpf) => {
    api
      .put(`/admin/troca-de-senha/${userCpf}`)
      .then((response) => {
        showNotification('success', 'Sucesso!', response.data.message);
      })
      .catch((error) => {
        return showNotification(
          'danger',
          'Atenção',
          error.response.data.message,
        );
      });
  };

  return (
    <>
      {loading && <Loading />}
      <S.ButtonsGrid>
        <Button
          model={'other'}
          leftIcon={true}
          icon={'back'}
          iconColor={'black'}
          onClick={() => setStep('list')}
        />
        {restrictAccess({
          idList: [125, 126, 109, 124, 121, 127, 70, 123, 69, 122, 119, 120],
        }) && (
          <>
            {/* {allowAccess({ level: [50] }) && (
              <Button
                type="button"
                onClick={() => forcePasswordChange(row.cpf)}
              >
                Forçar troca de senha
              </Button>
            )} */}

            {![47, 44, 43].includes(parseJwt().usu_nivel) && (
              <Button onClick={() => mirrorAccess(row.cpf)}>
                Acesso Espelho
              </Button>
            )}

            {allowAccess({ level: [50] }) && !adminIsFromClaro() && (
              <Button onClick={() => enableUserToEditProfile(row.cpf)}>
                Edição de Perfil
              </Button>
            )}
            {row.status === 1 &&
              allowAccess({ level: [50] }) &&
              !adminIsFromClaro() && (
                <Button onClick={() => inactiveUser(row.usu_id)}>
                  Inativar Usuário
                </Button>
              )}
            {row.status === 3 &&
              allowAccess({ level: [50] }) &&
              !adminIsFromClaro() && (
                <Button onClick={() => activeUser(row.usu_id)}>
                  Ativar Usuário
                </Button>
              )}
          </>
        )}
        {switchButtons()}
        <Download
          dropdownItems={false}
          rawData={extract}
          columns={columnsExtract}
          fileName={`Extrato de Participante - ${row.nome} - ${toDateTime(
            new Date(),
          )}`}
          buttonName="Extrato"
          placeSelf="center"
        />
      </S.ButtonsGrid>
      {url && <CopyToClipboard data={url} />}
      <S.ListWrapper>
        {accept && <Cards title="Aceite" dataList={accept} />}
        {personal && <Cards title="Pessoal" dataList={personal} />}
        {points && <Cards title="Pontuação" dataList={points} />}
      </S.ListWrapper>
    </>
  );
};

export default UserData;
