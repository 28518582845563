import { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import Loading from '~/components/Loading';
import Logo from '~/components/Logo';
import api from '~/services/api';

import CreateAnswers from './components/CreateAnswers';
import ForgotPassword from './components/ForgotPassword';
import * as S from './styles';
import Login from './components/Login';
import { HTTPErrorCallback } from '~/Helpers/Error';
import ResetPasswordEmail from './components/ResetPassword';

const SignIn = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [currentFormStep, setCurrentFormStep] = useState('login');
  const [contacts, setContacts] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [usuID, setUsuID] = useState(null);
  const [cpf, setCPF] = useState('');
  const [password, setPassword] = useState('');
  const [channelID, setChannelID] = useState(null);
  const [tempToken, setTempToken] = useState(null);
  const [optIn, setOptIn] = useState(false);
  const [usuCargo, setUsuCargo] = useState(null);
  const [changePasswordToken, setChangePasswordToken] = useState(null);
  const cicleInterval = useRef(null);
  const endInterval = useRef(null);
  const number = process.env.REACT_APP_NUMBER;

  useEffect(() => {
    if (cicleInterval.current) return history.push('/countdown');
  });

  useEffect(() => {
    countdownInterval();
    if (localStorage.getItem('userToken')) return history.push('/dashboard');

    if (localStorage.getItem('adminUserToken'))
      return history.push('/admin/dashboard');

    getContacts();

    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    if (!token) return;
    verifyEmailToken(token);
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const resetPassword = urlParams.get('reset');
    if (!resetPassword) return;
    setChangePasswordToken(resetPassword);
    verifyResetPasswordToken(resetPassword);
  }, []);

  const countdownInterval = () => {
    setLoading(true);
    api.get('/channel/countdown').then((r) => {
      cicleInterval.current = r.data.on_interval;
      endInterval.current = r.data.end_date;
      setLoading(false);
      return;
    });
  };

  const getContacts = () => {
    setLoading(true);
    api
      .get(`/fale-conosco`)
      .then((response) => {
        const { data: contacts } = response.data;
        setContacts(contacts);
        setLoading(false);
      })
      .catch((error) => {
        HTTPErrorCallback(error);
      });
  };

  const verifyResetPasswordToken = (token) => {
    setLoading(true);
    api
      .get(`/reset-password/verify-token?token=${token}`)
      .then((response) => {
        const success = response.data.success;
        if (success) {
          setCurrentFormStep('resetPassword');
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        HTTPErrorCallback(error);
        return history.push('/');
      });
  };

  const verifyEmailToken = (token) => {
    setLoading(true);
    api
      .get(`/validate-login-token/${token}`)
      .then((response) => {
        const {
          opt_in,
          register_secret_answers,
          usu_id,
          questions,
          user_data,
          temp_token,
        } = response.data;

        if (register_secret_answers) {
          setUsuID(usu_id);
          setChannelID(user_data.canal_id);
          setQuestions(questions);
          setTempToken(temp_token);
          setOptIn(opt_in);
          setCurrentFormStep('secretAnswers');
          setLoading(false);
          return;
        }

        if (opt_in) {
          setUsuID(usu_id);
          setChannelID(user_data.canal_id);
          setTempToken(temp_token);
          setOptIn(opt_in);
          history.push({
            pathname: '/optin',
            usu_id: usu_id,
            temp_token,
          });
          return;
        }
      })
      .catch((error) => {
        setLoading(false);
        HTTPErrorCallback(error);
      });
  };

  const getComponent = () => {
    switch (currentFormStep) {
      case 'login':
        return (
          <Login
            changePage={setCurrentFormStep}
            setQuestions={setQuestions}
            setLoading={setLoading}
            cpf={cpf}
            setCPF={setCPF}
            password={password}
            setPassword={setPassword}
            setUsuID={setUsuID}
            setChannelID={setChannelID}
            setTempToken={setTempToken}
            setOptIn={setOptIn}
            setUsuCargo={setUsuCargo}
          />
        );

      case 'forgotPassword':
        return (
          <ForgotPassword cpf={cpf} setCurrentFormStep={setCurrentFormStep} />
        );
      case 'secretAnswers':
        return (
          <CreateAnswers
            usuID={usuID}
            channelID={channelID}
            optIn={optIn}
            tempToken={tempToken}
            questions={questions}
            changePage={setCurrentFormStep}
            setLoading={setLoading}
            curretPassword={password}
            usuCargo={usuCargo}
          />
        );
      case 'resetPassword':
        return (
          <ResetPasswordEmail
            loading={loading}
            setCurrentFormStep={setCurrentFormStep}
            api={api}
            setLoading={setLoading}
            changePasswordToken={changePasswordToken}
            history={history}
            setCPF={setCPF}
            cpf={cpf}
          />
        );
      default:
        return <h1>Ops, houve um erro</h1>;
    }
  };

  return (
    <>
      <S.Container>
        {loading && <Loading />}
        <S.Background>
          <img src="/images/ciclo-8-login-background.jpg" alt="" />
        </S.Background>
        <S.Content>
          <S.ContainerLogo>
            <Logo origin="signin" />
          </S.ContainerLogo>
          {getComponent()}
          <S.Android>
            <a
              target="_blank"
              without="true"
              rel="noreferrer"
              href="https://play.google.com/store/apps/details?id=nex.digital.ClaroAppNex&hl=pt"
            >
              <img src="/images/android.png" alt="" />
            </a>
          </S.Android>
          <S.Central>
            <div>
              <h1>CENTRAL DE ATENDIMENTO</h1>
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '2px',
              }}
            >
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 24 24"
                width="20px"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path fill="none" d="M0 0h24v24H0z"></path>
                <path d="M19.05 4.91A9.816 9.816 0 0 0 12.04 2c-5.46 0-9.91 4.45-9.91 9.91 0 1.75.46 3.45 1.32 4.95L2.05 22l5.25-1.38c1.45.79 3.08 1.21 4.74 1.21 5.46 0 9.91-4.45 9.91-9.91 0-2.65-1.03-5.14-2.9-7.01zm-7.01 15.24c-1.48 0-2.93-.4-4.2-1.15l-.3-.18-3.12.82.83-3.04-.2-.31a8.264 8.264 0 0 1-1.26-4.38c0-4.54 3.7-8.24 8.24-8.24 2.2 0 4.27.86 5.82 2.42a8.183 8.183 0 0 1 2.41 5.83c.02 4.54-3.68 8.23-8.22 8.23zm4.52-6.16c-.25-.12-1.47-.72-1.69-.81-.23-.08-.39-.12-.56.12-.17.25-.64.81-.78.97-.14.17-.29.19-.54.06-.25-.12-1.05-.39-1.99-1.23-.74-.66-1.23-1.47-1.38-1.72-.14-.25-.02-.38.11-.51.11-.11.25-.29.37-.43s.17-.25.25-.41c.08-.17.04-.31-.02-.43s-.56-1.34-.76-1.84c-.2-.48-.41-.42-.56-.43h-.48c-.17 0-.43.06-.66.31-.22.25-.86.85-.86 2.07 0 1.22.89 2.4 1.01 2.56.12.17 1.75 2.67 4.23 3.74.59.26 1.05.41 1.41.52.59.19 1.13.16 1.56.1.48-.07 1.47-.6 1.67-1.18.21-.58.21-1.07.14-1.18s-.22-.16-.47-.28z"></path>
              </svg>
              <p>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`https://api.whatsapp.com/send?phone=551127766205`}
                >
                  {process.env.REACT_APP_NUMBER}
                </a>
              </p>
            </div>
          </S.Central>
        </S.Content>
      </S.Container>
    </>
  );
};

export default SignIn;
