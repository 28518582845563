import React, { useState, useEffect } from 'react';
import Loading from '~/components/Loading';
import { parseJwt } from '~/services/auth';
import { goTopPage } from '~/utils';
import { showNotification } from '~/utils/notificationBoxes';
import * as S from './styles';
import tableLinks from './tableLinks';
import usetableLink from '../../utils/useTableLink';

const Regulations = () => {
  const [loading, setLoading] = useState(true);
  const [regulation, setRegulation] = useState(null);

  useEffect(() => {
    initializePage();
  }, []);

  const initializePage = () => {
    goTopPage();
    fetchRegulation();
  };

  const fetchRegulation = () => {
    const { canal_id: canalId, cargo } = parseJwt();
    let tableLink = null;
    console.log(tableLink);
    switch (canalId) {
      case 2:
        tableLink = [75, 79, 85].includes(cargo)
          ? tableLinks.varejo.bko
          : tableLinks.varejo.default;
        break;
      case 4:
        tableLink = tableLinks.agenteAutorizado.default;
        break;
      case 5:
        tableLink = tableLinks.recarga.default;
        break;
      case 6:
        tableLink = tableLinks.aparelhos.default;
        break;
      case 8:
        tableLink = [76, 75, 91].includes(cargo)
          ? tableLinks.pap.bko
          : [74, 89, 92].includes(cargo)
          ? tableLinks.pap.default
          : tableLinks.pap.default;
        break;
      case 9:
        tableLink = [93, 94].includes(cargo)
          ? tableLinks.pc.premium
          : [95, 98, 99].includes(cargo)
          ? tableLinks.pc.condominio
          : [100, 102, 103].includes(cargo)
          ? tableLinks.pc.condominioBko
          : [101, 105].includes(cargo)
          ? tableLinks.pc.premiumBko
          : null;
        break;
      default:
        break;
    }

    if (tableLink) {
      setRegulation(tableLink);
    } else {
      showNotification(
        'warning',
        'Atenção',
        'Erro ao buscar o regulamento, tente novamente mais tarde!',
      );
    }

    setLoading(false);
  };

  const downloadRegulation = () => {
    if (regulation) {
      window.open(regulation);
    }
  };
  const tableLink = usetableLink();
  console.log(tableLink);
  return (
    <>
      {loading && <Loading />}
      <S.Wrapper>
        <S.Container>
          <S.PageTitle>
            <img src="/images/regulation-icon.png" alt="" />
            Tabela de Pontos:
          </S.PageTitle>
          {tableLink && (
            <>
              <iframe
                width="100%"
                height="800px"
                src={`${tableLink}#toolbar=0&navpanes=0&scrollbar=0`}
                frameBorder="0"
                title="pdf viewer"
              />
            </>
          )}
        </S.Container>
      </S.Wrapper>
    </>
  );
};

export default Regulations;
